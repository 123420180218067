import {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import config from '../../../config';
import './style.scss';
import {EcoSystemImage} from './ecosystem';
import {HowWorksImage} from './howworks';
import abountImage from './about.webp';
import msg1 from './msg1.webp';
import msg2 from './msg2.webp';
import djm from './djm.webp';
import djm_logo from './djm_logo.webp';
import kuvoForVenues from './kuvo-for-venues.webp';
import kuvoForCMOs from './kuvo-for-cmos.webp';
import {useApp} from '../../../contexts/AppContext';

const Page = styled.div`
  position: relative;
  z-index: 1;
  @media ${config.layout.topwide} {

  }
`;

const FG = styled.div`
  position: relative;
  z-index: 2;
`;

const TopSection = styled.section`
  height: 100vh;
  margin-top: -55px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;

  @media ${config.layout.topwide} {
    margin-top: calc(-160px - 34px);
  }

  h1 {
    position: absolute;
    left: 20px;
    bottom: 140px;
    font-weight: bold;
    color: #fff;
    font-size: 48px;
    line-height: 56px;
    @media ${config.layout.topwide} {
      left: unset;
      right: 50px;
      font-size: 110px;
      line-height: 130px;
      bottom: 45px;
      text-align: right;
    }
  }
`;

const AboutSection = styled.section`
  background-color: #F8F8F8;
  padding-top: 65px;

  @media ${config.layout.topwide} {
    padding-top: 150px;
  }
`;

const AboutDescriptionArea = styled.div`
  position: relative;

  h2.sp {
    padding: 0 20px;
    font-size: 50px;
    font-weight: bold;
    line-height: 66px;
  }

  @media ${config.layout.topwide} {
    display: flex;
    align-items: flex-start;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 27px;
  @media ${config.layout.topwide} {
    width: 50%;
    margin-top: unset;
  }
`;

const AboutDescription = styled.div`
  padding: 0 20px;

  p {
    margin-top: 30px;
    font-size: 32px;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 0;
    font-size: 26px;
    line-height: 40px;
  }

  @media ${config.layout.topwide} {
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;

    h2.pc {
      font-size: 70px;
      font-weight: bold;
      line-height: 70px;
      top: -8px;
      position: relative;
    }

    p {
      margin-top: 40px;
      font-size: 32px;
      line-height: 46px;
    }
  }
`;

const EcosystemSection = styled.section`
`;

const EcosystemSectionPreArea = styled.div`
  padding: 90px 20px 50px;
  background-color: #F8F8F8;

  h3 {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
  }

  p {
    margin: 32px 0 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }

  @media ${config.layout.topwide} {
    padding: 140px 50px 50px;
    p {
      max-width: 1300px;
    }
  }
`;

const ScrollIconWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
  height: 100px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media ${config.layout.topwide} {
    height: 160px;
    left: 50px;
  }


  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 20px;
    background-color: rgba(255, 255, 255, 1);
  }
`;

const ScrollIconCursor = styled.div`
  height: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  padding-left: 10px;
  position: relative;

`;

const HowWorksSection = styled.section`
`;

const HowWorksSectionPreArea = styled.div`
  padding: 90px 20px 50px;
  background-color: #F8F8F8;

  h3 {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
  }

  p {
    margin: 32px 0 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }

  @media ${config.layout.topwide} {
    padding: 140px 50px 50px;
    p {
      max-width: 1300px;
    }
  }
`;

const HowWorksImageArea = styled.div`
  height: 600px;
  background-color: rgba(0, 0, 0, 0.7);
  @media ${config.layout.topwide} {
    height: 700px;
  }
`;

const PartnershipSection = styled.section`
  background-color: #F8F8F8;
  padding-bottom: 10px;

  h3 {
    padding: 70px 20px 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    @media ${config.layout.topwide} {
      padding: 70px 50px 50px;
    }
  }
`;

const PartnershipSubSections = styled.div`
  display: flex;
  flex-direction: column;

  @media ${config.layout.topwide} {

    flex-direction: row;
  }
`;

const PartnershipSubSection = styled.div`
  @media ${config.layout.topwide} {
    width: 50%;
  }

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    padding: 0 20px;
    margin-bottom: 1.5em;
    @media ${config.layout.topwide} {
      padding: 0 50px;
    }
  }

  &.img {
    width: 100%;

    @media ${config.layout.topwide} {
      width: 50%;
    }

    img {
      margin-top: 16px;
      width: 100%;
      height: auto;
      display: inline-block;

      @media ${config.layout.topwide} {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  .djm_logo {
    padding: 50px 0 40px;

    img {
      display: block;
      width: 240px;
      margin: 0 auto;
    }

    @media ${config.layout.topwide} {
      padding: 50px;
      img {
        display: block;
        width: 320px;
        margin: 0 auto;
      }
    }
  }

  .dark-button {
    margin-top: 30px;
    width: calc(100% - 40px);
    @media ${config.layout.topwide} {
      margin-top: 70px;
      width: 350px;
    }
  }
`;

const MessageSection = styled.section`
  background-color: #F8F8F8;
  padding-bottom: 70px;
  border-bottom: 1px solid #D9D9D9;

  h3 {
    padding: 70px 20px 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    @media ${config.layout.topwide} {
      padding: 70px 50px 50px;
    }
  }
`;

const MessageSubSections = styled.div`
  display: flex;
  flex-direction: column;

  @media ${config.layout.topwide} {
    &.ss1 {
      flex-wrap: wrap;
      max-height: 650px;
    }

    &.ss2 {
      margin-top: 26px;
      flex-direction: row;
      align-items: flex-start;
    }
  }
`;

const MessageSubSection = styled.div`
  @media ${config.layout.topwide} {
    width: 50%;
  }

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    padding: 0 20px;
    margin-bottom: 1.5em;
    @media ${config.layout.topwide} {
      padding: 0 50px;
    }
  }

  img {
    margin-top: 16px;
    width: 100%;
    height: auto;
    display: inline-block;

    @media ${config.layout.topwide} {
      width: 100%;
      margin-top: 0;
    }
  }

  &.s11 {
    order: 1;
  }

  &.s12 {
    order: 3;
    @media ${config.layout.topwide} {
      order: 2;
    }
  }

  &.s13 {
    order: 2;
    text-align: right;
    margin-bottom: 40px;
    @media ${config.layout.topwide} {
      order: 3;
    }
  }

  &.s21 {
    margin-bottom: 40px;
  }

  &.s22 {

  }

`;

const ServicesSection = styled.section`
  background-color: #F8F8F8;
  padding: 35px 20px 70px;

  @media ${config.layout.topwide} {
    padding: 78px 50px 70px;
  }

  h2 {
    font-size: 50px;
    font-weight: bold;
    line-height: 66px;
    @media ${config.layout.topwide} {
      font-size: 70px;
      line-height: 90px;
    }
  }
`;

const ServicesPreSection = styled.div`
  margin-top: 27px;
  @media ${config.layout.topwide} {
    margin-top: 33px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    @media ${config.layout.topwide} {
      max-width: 1300px;
    }
  }
`;

const ServiceItems = styled.div`
  margin-top: 30px;
  @media ${config.layout.topwide} {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    gap: 50px;
    justify-content: space-between;
  }
`;

const ServiceItem = styled.div`
  &:first-child {
    margin-bottom: 35px;
  }

  @media ${config.layout.topwide} {
    width: calc((100% - 50px) / 2);
    &:first-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
  }

  img {
    margin-top: 22px;
    display: inline-block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .service-description {
    margin-top: 30px;
    margin-bottom: 30px;

    @media ${config.layout.topwide} {
      min-height: var(--sdh, 1px);
    }

    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
    }
  }

  .dark-button {
    width: 100%;
    @media ${config.layout.topwide} {
      width: 350px;
    }
  }
`;

const LinksSection = styled.section`
  border-top: 1px solid #D9D9D9;
  background-color: #F8F8F8;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const LinkItem = styled.div`
  width: 50%;
  text-align: center;
  padding: 0 20px 50px;
  @media ${config.layout.topwide} {
    padding-bottom: 100px;
  }

  &:first-child {
    border-right: 1px solid #D9D9D9;
  }

  h3 {
    margin-top: 40px;
    font-size: 30px;
    font-weight: bold;
    line-height: 39px;
    @media ${config.layout.topwide} {
      margin-top: 80px;
      font-size: 70px;
      line-height: 92px;
    }
  }

  p {
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0;
    height: 60px;
    @media ${config.layout.topwide} {
      margin-top: 20px;
      height: 90px;
      font-size: 16px;
    }
  }

  .dark-button {
    margin-top: 0;
    width: 100%;
    @media ${config.layout.topwide} {
      width: 170px;
    }
  }
`;

function ScrollIcon() {
  return (
      <ScrollIconWrap className={'top-scroll-wrap'}>
        <ScrollIconCursor
            className={'top-scroll-cursor'}>Scroll</ScrollIconCursor>
      </ScrollIconWrap>
  );
}

export default function TopPage() {
  const app = useApp();
  const vwRef = useRef(0);
  useEffect(() => {
    app.setTitle('');

    const setVh = () => {
      let vw = window.innerWidth;
      if (vwRef.current && vwRef.current === vw) {
        return;
      }
      vwRef.current = vw;
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      let sdh = 1;
      for (let d of document.getElementsByClassName('service-description')) {
        sdh = Math.max(sdh, d.querySelector('p').clientHeight);
      }
      document.documentElement.style.setProperty('--sdh', `${sdh}px`);
    };

    document.getElementById('body').classList.add('top');
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);
    setVh();
    return () => {
      document.getElementById('body').classList.remove('top');
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
      <Page>
        <FG>
          <TopSection id={'top-section'}>
            <h1>Empowering Music<br/>Through Data</h1>
            <ScrollIcon/>
          </TopSection>
          <AboutSection data-hash={'about'}>
            <AboutDescriptionArea>
              <h2 className={'sp'}>ABOUT</h2>
              <AboutImage src={abountImage}/>
              <AboutDescription>
                <h2 className={'pc'}>ABOUT</h2>
                <p>KUVO’s mission is to support electronic music creators.<br/>
                  Our aim is to collaborate with our partners to consistently
                  deliver reliable and transparent data, enable innovation, and
                  drive the electronic music industry forward.
                </p>
              </AboutDescription>
            </AboutDescriptionArea>
          </AboutSection>
          <EcosystemSection data-hash={'kuvo-ecosystem'}>
            <EcosystemSectionPreArea>
              <h3>KUVO ECOSYSTEM</h3>
              <p>KUVO supports music creators by empowering the industry with
                reliable data. Delivering valuable insights to venues and
                reliable reporting to CMOs, it enables more accurate royalty
                payments to creators. KUVO uses technology that works discreetly
                in the background to automate music play reporting for royalty
                purposes. It captures only the track data, which is never shared
                publicly, and no details of which DJ played which tracks.</p>
            </EcosystemSectionPreArea>
            <EcoSystemImage/>
          </EcosystemSection>
          <HowWorksSection data-hash={'how-kuvo-works'}>
            <HowWorksSectionPreArea>
              <h3>HOW KUVO WORKS</h3>
              <p>The electronic music industry ecosystem – which generates
                royalties for music creators when DJs play their tracks – is
                built from a deeply interdependent framework of people and
                companies. This diagram outlines the flow of music, rights, and
                royalties for club and DJ events. </p>
            </HowWorksSectionPreArea>
            <HowWorksImage/>
          </HowWorksSection>

          <PartnershipSection data-hash={'partnership'}>
            <h3>PARTNERSHIP WITH DJ MONITOR</h3>

            <PartnershipSubSections>
              <PartnershipSubSection className={'img'}>
                <img src={djm}/>
              </PartnershipSubSection>
              <PartnershipSubSection>
                <div className={'djm_logo'}>
                  <img src={djm_logo}/>
                </div>

                <p>We’ve partnered with DJ Monitor, the market-leading music
                  recognition technology for electronic music venues and events.
                  This ground-breaking collaboration between KUVO and DJ Monitor
                  enables us to provide a superior music identification and
                  insights service.</p>


                <Link
                    className="dark-button center-fixed"
                    to={'/partnership-with-djmonitor/'}
                >
                  <span>Learn more</span>
                </Link>

              </PartnershipSubSection>
            </PartnershipSubSections>
          </PartnershipSection>

          <MessageSection data-hash={'message'}>
            <h3>WHY ARE WE DOING THIS?</h3>
            <MessageSubSections className={'ss1'}>
              <MessageSubSection className={'s11'}>
                <p>We at AlphaTheta Corporation are committed to supporting the
                  creators of electronic music via the evolution of the KUVO
                  service with our Pioneer DJ brand. </p>
                <p>We recognize that music creators (producers, recording
                  artists, songwriters, composers, and producer/DJs) are the
                  lifeblood of the electronic music ecosystem. However, since
                  the dawn of DJ culture, collecting accurate information on the
                  music being played in clubs and electronic music venues has
                  been challenging. Without accurate information on what is
                  actually played, fewer electronic music creators receive
                  royalty income. </p>
              </MessageSubSection>
              <MessageSubSection className={'s12'}>
                <p>AlphaTheta, through its Pioneer DJ brand, is uniquely
                  positioned to make a difference and we recognize our cultural
                  responsibility to do so. </p>
                <p>Through our evolved KUVO service, we intend to create a
                  balanced offering which incentivizes venues and events to
                  engage with and deploy our data collection/performance
                  reporting solutions, and to offer CMOs the most accurate,
                  reliable, affordable, and “friction-free” reporting on a
                  global scale to enable accurate royalty distributions to the
                  creators and rightsholders of the music that DJs play. </p>
              </MessageSubSection>
              <MessageSubSection className={'s13'}>
                <img src={msg1}/>
              </MessageSubSection>
            </MessageSubSections>
            <MessageSubSections className={'ss2'}>
              <MessageSubSection className={'s21'}>
                <img src={msg2}/>
              </MessageSubSection>
              <MessageSubSection className={'s22'}>
                <p>The system enables DJs to support the creators of the music
                  they play without needing to do anything more than play their
                  tracks. No action from DJs is required for KUVO to function –
                  it captures only the track data of the music played. The data
                  is never shared publicly and does not include details of the
                  performing DJ.</p>
                <p>KUVO is a positive, creator-supporting initiative which
                  provides insights and benefits for venues that participate,
                  and it creates no negative impact or inconvenience for
                  DJs.</p>
                <p>For transparency on how KUVO will generate income to sustain
                  itself: the proposed model involves charging selected industry
                  partners for data enhancement, music play insights, and
                  analytics services. To clarify: no charges for KUVO services
                  are incurred by venues, music creators, or DJs (currently, no
                  KUVO services are provided for DJs). </p>
              </MessageSubSection>
            </MessageSubSections>
          </MessageSection>
          <ServicesSection data-hash={'services'}>
            <h2>SERVICES</h2>
            <ServicesPreSection>
              <p>KUVO is built to deliver valuable music insights and incentives
                to enhance venue businesses and provide CMOs with an opportunity
                to better support the creators and rightsholders of the music
                DJs play. Importantly, it also respects DJ setlist privacy – no
                playlists are publicized, and no details of which DJ played
                which tracks are captured.</p>
            </ServicesPreSection>
            <ServiceItems>
              <ServiceItem>
                <h3>KUVO For Venues</h3>
                <img src={kuvoForVenues}/>
                <div className={'service-description'}>
                  <p>Venues can privately access trend and summary information
                    on music played, receive exclusive benefits on Pioneer DJ
                    and AlphaTheta equipment, and enable the industry to make
                    more accurate payments of royalties to creators.
                  </p>
                </div>
                <Link
                    className="dark-button center-fixed"
                    to={'/contact/'}
                    state={{
                      category: 'New venue account',
                    }}
                >
                  <span>Contact us</span>
                </Link>
              </ServiceItem>
              <ServiceItem>
                <h3>KUVO For CMOs</h3>
                <img src={kuvoForCMOs}/>
                <div className={'service-description'}>
                  <p>KUVO provides accurate, reliable, and secure music play
                    data
                    which can enable CMOs to distribute royalties more
                    accurately
                    to the creators and rightsholders of the music DJs play.
                    We’re
                    also developing “dual technology” solutions which bring
                    together Music Recognition Technology (MRT) – through our
                    partnership with DJ Monitor – and KUVO’s Direct Metadata
                    Collection (DMC) technology.</p>
                </div>
                <Link
                    className="dark-button center-fixed"
                    to={'/contact/'}
                    state={{
                      category: 'CMO account',
                    }}
                >
                  <span>Contact us</span>
                </Link>
              </ServiceItem>
            </ServiceItems>
          </ServicesSection>
          <LinksSection>
            <LinkItem>
              <h3>FAQ</h3>
              <p>How can we <br className={'sp'}/> help you?</p>
              <Link
                  className="dark-button center-fixed"
                  to={'/faq/'}
              >
                <span>More</span>
              </Link>
            </LinkItem>
            <LinkItem>
              <h3>CONTACT</h3>
              <p>Can’t find what you’re looking for?</p>
              <Link
                  className="dark-button center-fixed"
                  to={'/contact/'}
              >
                <span>More</span>
              </Link>
            </LinkItem>
          </LinksSection>
        </FG>
      </Page>
  );
}