import styled from 'styled-components';
import config from '../../../config';
import './style.scss';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

const HeaderOuter = styled.header`
  z-index: 3;
  width: 100%;
  display: block;
  height: calc(55px + 34px);
  background-color: #F8F8F8;
  transition: background-color 0.3s ease-out;
  position: sticky;
  top: -34px;
  left: 0;
  border-bottom: 1px solid #D9D9D9;
  padding-top: 34px;

  &.top {
    border-bottom-color: transparent;
  }

  svg {
    .hlst0 {
      fill: #000000;
      transition: fill 0.3s ease-out;
    }
  }

  @media ${config.layout.mobile} {
    &.opened {
      background-color: #000000;
      border-bottom-color: transparent;

      svg {
        .hlst0 {
          fill: #ffffff;
        }
      }
    }
  }

  @media ${config.layout.wide} {
    padding-top: unset;
    min-width: 1200px;
    display: flex;
    align-items: center;
    position: sticky;
    top: calc(-60px - 34px);
    height: calc(160px + 34px);
  }
`;

const HeaderInner = styled.div`
  height: calc(100% - 34px);
  position: relative;
  
  @media ${config.layout.wide} {
    height: 100px;
    width: 100%;
    min-width: 1200px;
    position: sticky;
    top: 0;
    padding-top: calc((100px - 66px) / 2);
    margin-top: 34px;
  }
`;

const HeaderContent = styled.div`

  svg {
    width: 116px;
  }


  @media ${config.layout.wide} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 60px 0 50px;
    height: 66px;

    svg {
      width: 165px;
      height: 66px;
    }
  }
`;

const HeaderMenuArea = styled.div`
  @media ${config.layout.mobile} {
    width: 100vw;
    background: #000000;
    height: calc(100vh - 55px);
    position: fixed;
    top: 55px;
    display: none;
    &.opened {
      display: block;
    }

    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media ${config.layout.wide} {

  }
`;

const HeaderMenuItems = styled.div`
  @media ${config.layout.mobile} {
    height: calc(100% + 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    gap: 40px;
    position: relative;
    transition: transform 0.2s ease-out;

    &.sub-menu-open {
      transform: translate(-100vw, 0);
    }
  }
  @media ${config.layout.wide} {
    display: flex;
    align-items: center;
    gap: 54px;
    position: relative;
  }
`;

const HeaderMenuLinkElm = styled.a`
  text-decoration: none;

  @media ${config.layout.mobile} {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 1em;
    &:hover {
      color: #fff;
    }

    width: 160px;
  }

  @media ${config.layout.wide} {
    color: #000;
    font-weight: bold;
    top: 12px;
    position: relative;
    &:hover {
      color: #000;

      &:after {
        content: '';
        display: block;
        height: 4px;
        background-color: #000;
        position: absolute;
        bottom: -6px;
        width: 100%;
      }
    }
  }
`;

const HeaderLogoLinkElm = styled.a`
  display: block;

  @media ${config.layout.mobile} {
    position: absolute;
    left: calc(50% - (116px / 2) + 2px);
    top: 5px;
  }

  @media ${config.layout.wide} {

  }
`;

const HeaderMenuButtonElm = styled.div`

  position: absolute;
  width: 20px;
  height: 20px;
  right: 15px;
  top: 15px;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 3px;
    background-color: #000;
    transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  }

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 12px;
    background-color: #000;
    transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  }

  &.opened {
    &:before {
      width: 22px;
      left: -1px;
      top: 8px;
      transform: rotateZ(45deg);
      background-color: #fff;
    }

    &:after {
      width: 22px;
      left: -1px;
      top: 8px;
      transform: rotateZ(-45deg);
      background-color: #fff;
    }
  }

  @media ${config.layout.wide} {
    display: none;
  }
`;

function HeaderLogoLink({
  closeModal,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  //console.log(location);

  if (location.pathname == '/') {
    // 同じページ内に既にいる
    return (
        <HeaderLogoLinkElm
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              if (location.hash) {
                navigate('/');
              } else {
                window.scrollTo(0, 0);
//                window.location.reload();
              }
            }}
        >
          <HeaderLogo/>
        </HeaderLogoLinkElm>
    );
  }

  const onClick = (e) => {
    e.preventDefault();
    closeModal();
    navigate('/');
  };

  return (
      <HeaderLogoLinkElm href="#" onClick={onClick}>
        <HeaderLogo/>
      </HeaderLogoLinkElm>
  );
}

export function HeaderLogo() {
  return (
      <svg version="1.1" id="header-logo" xmlns="http://www.w3.org/2000/svg"
           x="0px"
           y="0px" viewBox="0 0 165 66"
           style={{'enableBackground': 'new 0 0 165 66'}}>
        <g id="hlgroup_1" transform="translate(-204.232 -204.318)">
          <g id="hlgroup_957" transform="translate(204.232 215.585)">
            <path id="hlpath_121" className="hlst0" d="M87.38,31.24c0.1,0.26,0.4,0.38,0.65,0.27c0.13-0.05,0.23-0.16,0.28-0.29l9.42-28.64h6.17
			L92.77,33.86c-0.72,2.24-2.93,3.65-5.26,3.38c-2.71-0.08-4.2-1.16-5.17-3.81L71.36,2.61h6.29L87.38,31.24z"/>
            <rect id="hlrect_716" x="0.48" y="2.6" className="hlst0"
                  width="5.65"
                  height="34.41"/>
            <path id="hlpath_122" className="hlst0" d="M65.34,13.06c-0.19-1.56-1.61-2.68-3.18-2.48c-1.4,0.17-2.47,1.35-2.5,2.76l0,0l-0.01,10.11
			c-0.15,4.88-4.22,8.72-9.1,8.57c-2.67-0.08-5.16-1.36-6.78-3.49c-1.08-1.56-1.62-3.42-1.55-5.31V2.6h-5.76l0.01,20.6
			c-0.01,0.72,0.04,1.44,0.12,2.15c0.4,4.19,2.79,7.93,6.43,10.04c2.85,1.67,6.17,2.37,9.45,2c3.35-0.2,6.52-1.6,8.92-3.94
			c2.67-2.63,4.12-6.24,4.02-9.99L65.34,13.06z"/>
            <circle id="hlel_42" className="hlst0" cx="62.48" cy="5.51"
                    r="2.88"/>
            <path id="hlpath_123" className="hlst0" d="M136.98,6.95c-5.42-5.42-13.69-6.79-20.57-3.41l2.45,5.22c6-3.02,13.3-0.6,16.32,5.4
			c3.02,6,0.6,13.3-5.4,16.32c-6,3.02-13.3,0.6-16.32-5.4c-0.59-1.17-0.98-2.43-1.17-3.73l0,0c-0.19-1.58-1.63-2.7-3.21-2.5
			c-1.58,0.19-2.7,1.63-2.5,3.21c0.01,0.06,0.02,0.12,0.03,0.18l0,0c1.44,9.79,10.55,16.55,20.33,15.1
			c9.79-1.44,16.55-10.55,15.1-20.33C141.48,13.19,139.71,9.67,136.98,6.95L136.98,6.95z"/>
            <circle id="hlel_43" className="hlst0" cx="109.99" cy="13.69"
                    r="2.9"/>
            <path id="hlpath_124" className="hlst0" d="M15.53,20.22c-0.54-0.44-0.62-1.23-0.18-1.77c0.06-0.07,0.13-0.14,0.2-0.2l13.8-15.66h-7.07
			L10.41,16.16c-2.05,2.51-2.02,4.52,0.08,6.98l11.97,13.9l7.4-0.01L15.53,20.22z"/>
          </g>

        </g>
      </svg>
  );
}

function HeaderLink({
  to, closeModal, children,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  //console.log(location);

  let toPath = to.replace(/#.*?$/, '');
  if (toPath == location.pathname) {
    // 同じページ内に既にいる

    if (location.hash || to.replace(/^.+?#/, '')) {
      return (
          <HeaderMenuLinkElm
              data-hashlink={1}
              href={to}
              onClick={(e) => {
                closeModal();
                global.scrollToHash(`#${to.replace(/^.+?#/, '')}`);
              }}
          >
            <span>{children}</span>
          </HeaderMenuLinkElm>
      );
    } else {
      return (
          <HeaderMenuLinkElm
              data-hashlink={1}
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
                window.scrollTo(0, 0);
              }}
          >
            <span>{children}</span>
          </HeaderMenuLinkElm>
      );
    }
  }

  const onClick = (e) => {
    e.preventDefault();
    closeModal();
    navigate(to);
  };

  return (
      <HeaderMenuLinkElm href="#" onClick={onClick}>
        <span>{children}</span>
      </HeaderMenuLinkElm>
  );
}

function HeaderModalButton({
  toggleModal, isOpen,
}) {
  return (
      <HeaderMenuButtonElm className={isOpen ? 'opened' : 'closed'}
                           onClick={toggleModal}/>
  );
}

const HeaderLinkParentElm = styled.a`
  text-decoration: none;

  @media ${config.layout.wide} {
    color: #000;
    font-weight: bold;
    top: 12px;
    position: relative;

    span {
      position: relative;
      pointer-events: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 50px;
    }

    &:hover {
      color: #000;

      &:after {
        content: '';
        display: block;
        height: 4px;
        background-color: #000;
        position: absolute;
        bottom: -6px;
        width: 100%;
      }
    }
  }

  @media ${config.layout.mobile} {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 1em;
    &:hover {
      color: #fff;
    }

    width: 160px;
    & > span {
      position: relative;

      &:after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11.314" height="16.971" viewBox="0 0 11.314 16.971"><g id="グループ_1539" data-name="グループ 1539" transform="translate(-286.515 -296.516)"><rect id="長方形_437" data-name="長方形 437" width="12" height="4" transform="translate(289.344 296.516) rotate(45)" fill="%23fff"/><rect id="長方形_498" data-name="長方形 498" width="12" height="4" transform="translate(286.515 310.658) rotate(-45)" fill="%23fff"/></g></svg>');
        width: 11.314px;
        height: 16.971px;
        background-repeat: no-repeat;
        position: absolute;
        left: 135px;
        top: 50%;
        margin-top: -7px;
      }
    }
  }
`;

const HeaderLinkSubItemsElmWrap = styled.div`

  @media ${config.layout.mobile} {
    position: absolute;
    left: 100vw;
    top: 0;
    width: 100vw;
    height: calc(100% + 20px);
  }

  @media ${config.layout.wide} {
    display: block;
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;
    max-height: 0px;
    overflow-y: hidden;
    position: absolute;
    top: 45px;
    left: -75px;
    border-radius: 4px;
    background-color: #707070;

    &.opened {
      max-height: unset;
      pointer-events: all;
      opacity: 1;
    }
  }

`;

const HeaderLinkSubItemsElm = styled.div`

  display: flex;
  flex-direction: column;

  @media ${config.layout.mobile} {
    width: 310px;
    gap: 25px;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    padding-bottom: 100px;
  }

  @media ${config.layout.wide} {
    width: 196px;
  }

`;

function HeaderLinkSubItems({
  closeModal, isOpen, children,
}) {

  return (
      <HeaderLinkSubItemsElmWrap className={isOpen ? 'opened' : ''}>
        <HeaderLinkSubItemsElm>{children}</HeaderLinkSubItemsElm>
      </HeaderLinkSubItemsElmWrap>
  );
}

const HeaderLinkSubItemElm = styled.a`

  @media ${config.layout.mobile} {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 39px;
    &:hover {
      color: #fff;
    }

    text-decoration: none;
  }

  @media ${config.layout.wide} {
    padding: 20px;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #646464;


    &:last-child {
      border-bottom: none;
    }

    &.last {
      border-bottom: none;
    }

    transition: background-color 0.1s linear;

    &:hover {
      background-color: #000000;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: #fff;
    }
  }
`;

function HeaderLinkSubItem({
  to, closeModal, isLast, children,
}) {

  const location = useLocation();
  const navigate = useNavigate();
  //console.log(location);
  let toPath = to.replace(/#.*?$/, '');
  if (toPath == location.pathname) {
    // 同じページ内に既にいる

    if (location.hash || to.replace(/^.+?#/, '')) {
      return (
          <HeaderLinkSubItemElm
              data-hashlink={1}
              href={to}
              className={isLast ? 'last' : ''}
              onClick={(e) => {
                closeModal();
                global.scrollToHash(`#${to.replace(/^.+?#/, '')}`);
              }}

          >
            <span>{children}</span>
          </HeaderLinkSubItemElm>
      );
    } else {
      return (
          <HeaderLinkSubItemElm
              data-hashlink={1}
              href={'#'}
              className={isLast ? 'last' : ''}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
                window.scrollTo(0, 0);
              }}
          >
            <span>{children}</span>
          </HeaderLinkSubItemElm>
      );
    }
  }

  const onClick = (e) => {
    e.preventDefault();
    closeModal();
    navigate(to);
  };

  return (
      <HeaderLinkSubItemElm
          className={isLast ? 'last' : ''}
          href="#" onClick={onClick}>
        <span>{children}</span>
      </HeaderLinkSubItemElm>
  );
}

function HeaderLinkParent({
  closeModal, closeChildren, openChildren, isChildrenOpen, label, children,
}) {
  const ref = useRef(null);

  return (
      <HeaderLinkParentElm
          ref={ref}
          className={'header-link-parent'}
          onMouseOver={(e) => {
            const isMobile = window.matchMedia(
                `${config.layout.mobile}`).matches;
            if (!isMobile) {
              openChildren();
            }
          }}
          onMouseOut={(e) => {
            const isMobile = window.matchMedia(
                `${config.layout.mobile}`).matches;
            if (!isMobile && !ref.current.querySelector(':hover') && !ref.current.parentNode.querySelector(':hover')) {
              closeChildren();
            }
          }}
          onClick={(e) => {
            const isMobile = window.matchMedia(
                `${config.layout.mobile}`).matches;
            if (isMobile) {
              openChildren();
            }
          }}
      >
        <span>{label}</span>
        <HeaderLinkSubItems
            isOpen={isChildrenOpen}
        >{children}</HeaderLinkSubItems>
      </HeaderLinkParentElm>
  );
}

const HeaderSubMenuBackButtonElm = styled.a`

  @media ${config.layout.wide} {
    display: none;
  }

  @media ${config.layout.mobile} {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 39px;
    margin-top: 15px;
    &:hover {
      color: #fff;
    }

    text-decoration: none;

    & > span {
      position: relative;
      padding-left: 40px;

      &:after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11.314" height="16.971" viewBox="0 0 11.314 16.971"><g id="グループ_1539" data-name="グループ 1539" transform="translate(-286.515 -296.516)"><rect id="長方形_437" data-name="長方形 437" width="12" height="4" transform="translate(289.344 296.516) rotate(45)" fill="%23fff"/><rect id="長方形_498" data-name="長方形 498" width="12" height="4" transform="translate(286.515 310.658) rotate(-45)" fill="%23fff"/></g></svg>');
        width: 11.314px;
        height: 16.971px;
        transform: rotateZ(180deg);
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
      }
    }
  }

`;

function HeaderSubMenuBackButton({
  closeChildren,
}) {
  return (
      <HeaderSubMenuBackButtonElm
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            closeChildren(true);
            return false;
          }}
          href={'#'}
      >
        <span>Back</span>
      </HeaderSubMenuBackButtonElm>
  );
}

function ATCGlobalHeader() {

  return (
      <div id={"atc-global-header"}>
        <a id="atc-global-link" href={"https://alphatheta.com/"} target={"_blank"}>AlphaTheta</a>
      </div>
  );
}

export default function HeaderNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setIsAboutOpen(false);
  };

  const toggleModal = () => {
    setIsAboutOpen(false);
    setIsOpen(!isOpen);

    // 画面のスクロール位置が34px未満の場合は34pxまでスクロールさせる
    if (window.scrollY < 34) {
      window.scrollTo(0, 34);
    }
  };

  const closeAbout = (force) => {
    setIsAboutOpen(false);
  };

  const openAbout = () => {
    setIsAboutOpen(true);
  };

  return (
      <HeaderOuter className={`header ${isOpen
          ? 'opened'
          : 'closed'} ${location.pathname == '/' ? 'top' : ''}`}
                   id={'nav-header'}>

        <ATCGlobalHeader/>

        <HeaderInner>
          <HeaderContent>
            <HeaderLogoLink closeModal={closeModal}/>
            <HeaderModalButton toggleModal={toggleModal} isOpen={isOpen}/>
            <HeaderMenuArea className={isOpen ? 'opened' : 'closed'}>
              <HeaderMenuItems className={`header-menu-items ${isAboutOpen
                  ? 'sub-menu-open'
                  : ''}`}>
                <HeaderLink to={'/'} closeModal={closeModal}>TOP</HeaderLink>

                <HeaderLinkParent
                    closeModal={closeModal}
                    closeChildren={closeAbout}
                    openChildren={openAbout}
                    isChildrenOpen={isAboutOpen}
                    label={'ABOUT'}
                >
                  <HeaderLinkSubItem
                      closeModal={closeModal}
                      to={'/#about'}>ABOUT</HeaderLinkSubItem>
                  <HeaderLinkSubItem
                      closeModal={closeModal}
                      to={'/#kuvo-ecosystem'}>KUVO ECOSYSTEM</HeaderLinkSubItem>
                  <HeaderLinkSubItem
                      closeModal={closeModal}
                      to={'/#how-kuvo-works'}>HOW KUVO WORKS</HeaderLinkSubItem>
                  <HeaderLinkSubItem
                      closeModal={closeModal}
                      to={'/#partnership'}>PARTNERSHIP WITH<br/>DJ
                    MONITOR</HeaderLinkSubItem>
                  <HeaderLinkSubItem
                      closeModal={closeModal}
                      isLast={true}
                      to={'/#message'}>WHY ARE WE DOING
                    THIS?</HeaderLinkSubItem>

                  <HeaderSubMenuBackButton
                      closeChildren={closeAbout}
                  />
                </HeaderLinkParent>

                <HeaderLink to={'/#services'}
                            closeModal={closeModal}>SERVICES</HeaderLink>
                <HeaderLink to={'/faq/'}
                            closeModal={closeModal}>FAQ</HeaderLink>
                <HeaderLink to={'/contact/'}
                            closeModal={closeModal}>CONTACT</HeaderLink>
              </HeaderMenuItems>
            </HeaderMenuArea>
          </HeaderContent>
        </HeaderInner>
      </HeaderOuter>
  );

}
