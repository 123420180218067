import {useState, useRef, useEffect} from 'react';
import {Routes, Route, Navigate, Outlet, useLocation} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';
import config from '../config';
import TopPage from '../components/pages/TopPage';
import {AppContextProvider} from '../contexts/AppContext';
import {CustomDialogContextProvider} from '../contexts/CustomDialogContext';
import CustomDialog from '../components/organisms/CustomDialog';
import HeaderNav from '../components/organisms/HeaderNav';
import Footer from '../components/organisms/Footer';
import FAQPage from '../components/pages/FAQPage';
import ContactPage from '../components/pages/ContactPage';
import DJMonitorPage from '../components/pages/DJMonitorPage';
import NotFoundPage from '../components/pages/NotFoundPage';

let autoScrollBlocked = false;
window.blockAutoScroll = function() {
  autoScrollBlocked = true;
};

const LayoutWrapper = styled.div`
  position: relative;
  padding: 0 20px 0;

  &.full-width {
    padding: 0 0 0;
  }

  @media ${config.layout.wide} {
    padding: 0 50px;
    min-width: 1200px;
    &.full-width {
      padding: unset;
    }
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 590px;

  &.full-width {
    .main-content-inner {
      padding-bottom: 0;
    }
  }

  @media ${config.layout.wide} {
    max-width: 930px;
    margin: 0 auto;
    min-height: 640px;

    &.full-width {
      max-width: unset;
    }
  }
`;

const ContentInner = styled.div`
  //min-height: 200vh;

  padding-bottom: 150px;
  @media ${config.layout.wide} {
    padding-bottom: 150px;
  }
`;

export default function App() {
  const loadingStateRef = useRef({
    count: 0,
  });
  const [showLoading, setShowLoading] = useState(false);

  window.incrementLoading = () => {
    loadingStateRef.current.count++;
    setShowLoading(0 < loadingStateRef.current.count);
  };
  window.decrementLoading = () => {
    loadingStateRef.current.count = Math.max(
        loadingStateRef.current.count - 1,
        0,
    );
    setShowLoading(0 < loadingStateRef.current.count);
  };
  window.clearLoading = () => {
    loadingStateRef.current.count = 0;
    setShowLoading(0 < loadingStateRef.current.count);
  };

  return (
      <AppContextProvider>

        <CustomDialogContextProvider>
          <LoadingOverlay
              active={showLoading}
              spinner={
                <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 90 90"
                    enableBackground="new 0 0 90 90"
                >
                  <linearGradient
                      id="SVGID_1_"
                      gradientUnits="userSpaceOnUse"
                      x1="45"
                      y1="6.73"
                      x2="45"
                      y2="89.5"
                  >
                    <stop offset="0" style={{stopColor: '#BDDAF0'}}/>
                    <stop offset="1" style={{stopColor: '#007DE1'}}/>
                  </linearGradient>
                  <path
                      fill="url(#SVGID_1_)"
                      d="M45,0.5C20.42,0.5,0.5,20.42,0.5,45c0,24.58,19.92,44.5,44.5,44.5c24.58,0,44.5-19.92,44.5-44.5
	C89.5,20.42,69.58,0.5,45,0.5z M45,74.67c-16.38,0-29.67-13.28-29.67-29.67S28.62,15.33,45,15.33S74.67,28.62,74.67,45
	S61.38,74.67,45,74.67z"
                  />
                </svg>
              }
              text="LOADING"
              styles={{
                wrapper: {
                  minHeight: '100vh',
                  backgroundColor: 'transparent',
                },
              }}
          >
            <Routes>
              <Route element={<BaseLayout/>}>
                <Route path="/" element={<TopPage/>}/>
                <Route path="/faq" element={<FAQPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/partnership-with-djmonitor" element={<DJMonitorPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
              </Route>
            </Routes>
          </LoadingOverlay>
          <CustomDialog/>
        </CustomDialogContextProvider>
      </AppContextProvider>
  );
}

let scrollTimer = null;
let scrollTargetHash = null;
let scrollTimeout = Date.now();

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return {top: _y, left: _x};
}

function scrollWindowByTimer() {
  try {
    scrollTimer = null;
    if (!scrollTargetHash || scrollTimeout < Date.now()) {
      return;
    }
    if (window.initialFetching) {
      console.log(`Waiting initial fetching`);
      scrollTimer = setTimeout(scrollWindowByTimer, 50);
      return;
    }
    let elm = document.querySelector(`[data-hash="${scrollTargetHash}"]`);
    if (!elm) {
      console.log(`Waiting dom building`);
      scrollTimer = setTimeout(scrollWindowByTimer, 50);
      return;
    }
    scrollTimer = null;

    let top = getOffset(elm).top;
    let header = document.getElementById('nav-header');
    if (header) {
      let hr = header.getBoundingClientRect();
      if (hr.height >= 80) {
        top -= 80;
      } else {
        top -= hr.height;
      }
    }
    scrollTargetHash = null;
    window.scrollTo(0, top);
  } catch (e) {
    console.error(e);
  }
}

global.scrollToHash = function(hash) {
  clearTimeout(scrollTimer);
  scrollTimer = null;
  scrollTargetHash = null;

  console.log('HASH(page)', hash);
  scrollTargetHash = hash.replace(/^#/, '');
  scrollTimeout = Date.now() + 10 * 1000;
  scrollTimer = setTimeout(scrollWindowByTimer, 100);
};

function BaseLayout() {
  const {pathname, hash, search} = useLocation();
 useEffect(() => {
    clearTimeout(scrollTimer);
    scrollTimer = null;
    scrollTargetHash = null;


    if (autoScrollBlocked) {
      console.log('Cancelled scrolling');
      setTimeout(() => {
        autoScrollBlocked = false;
        console.log('Reset autoScrollBlocked flag');
      }, 500);
      return;
    }
    if (!hash) {
      window.scrollTo(0, 0);
      console.log('HASH', hash);
    } else {
      console.log('HASH', hash);
      scrollTargetHash = hash.replace(/^#/, '');
      scrollTimeout = Date.now() + 10 * 1000;
      scrollTimer = setTimeout(scrollWindowByTimer, 100);
    }
  }, [pathname, search, hash]);

  const layoutClass= (pathname === '/'  || /partnership-with-djmonitor/.test(pathname)) ? 'full-width' : '';

  return (
      <>
        <HeaderNav/>
        <LayoutWrapper
            className={`layout-wrapper ${layoutClass} ${pathname == '/' ? 'top' : ''}`}>
          <Content className={`main-content ${layoutClass} ${pathname == '/' ? 'top' : ''}`}>
            <ContentInner className="main-content-inner">
              <Outlet/>
            </ContentInner>
          </Content>
        </LayoutWrapper>
        <Footer/>
      </>
  );
}

/*
function PlainLayout() {
  const {pathname, hash, search} = useLocation();
  useEffect(() => {
    clearTimeout(scrollTimer);
    scrollTimer = null;
    scrollTargetHash = null;
    if (autoScrollBlocked) {
      console.log('Cancelled scrolling');
      setTimeout(() => {
        autoScrollBlocked = false;
        console.log('Reset autoScrollBlocked flag');
      }, 500);
      return;
    }
    if (!hash) {
      window.scrollTo(0, 0);
      console.log('HASH', hash);
    } else {
      console.log('HASH', hash);
      scrollTargetHash = hash.replace(/^#/, '');
      scrollTimeout = Date.now() + 10 * 1000;
      scrollTimer = setTimeout(scrollWindowByTimer, 100);
    }
  }, [pathname, search]);

  return (
      <>
        <Outlet/>
      </>
  );
}
 */
